<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleAddProductsDialog" persistent>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3"
              >Category : {{ this.category.name }} ({{
                this.category.code
              }})</span
            >
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="9">
                      <v-autocomplete
                        dense
                        autofocus
                        hide-details
                        outlined
                        :label="$t('name')"
                        v-model="ProductToAdd.id"
                        :items="listProducts"
                        item-text="name"
                        item-value="id"
                        :search-input.sync="search_product_input"
                        :loading="isLoadingProducts"
                        no-filter
                        @keyup.enter="addProductByEnterKey"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn
                        block
                        outlined
                        color="primary"
                        @click="addProduct(ProductToAdd)"
                        :disabled="!is_valid"
                        >{{ $t("add") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>

            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <div v-if="specialCategoryPorducts">
                    <v-data-table
                      :headers="ProductsHeaders"
                      :items="specialCategoryPorducts"
                      hide-default-footer
                      dense
                      :items-per-page="-1"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon color="red" @click="removeProduct(item)">
                          mdi-close
                        </v-icon>
                      </template>
                      <template v-slot:no-data>
                        {{ $t("empty") }}
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(category)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
    }),

    isVisibleAddProductsDialog: {
      get() {
        return this.$store.state.specialCategories.isVisibleAddProductsDialog;
      },
      set(value) {
        this.$store.commit(
          "specialCategories/IS_VISIBLE_ADD_PRODUCTS_DIALOG",
          value
        );
      },
    },

    category: {
      get() {
        return this.$store.state.specialCategories.category;
      },
      set(value) {
        this.$store.commit("specialCategories/CATEGORY", value);
      },
    },

    specialCategoryPorducts: {
      get() {
        return (
          this.$store.state.specialCategories.category.productsOfSpecial || []
        );
      },
      set(value) {
        this.$store.commit("specialCategories/SET_PRODUCTS", value);
      },
    },
  },

  watch: {
    search_product_input: function (search) {
      if (this.isLoadingProducts) return;

      this.$store.dispatch("products/leaf", {
        itemsPerPage: 30,
        search,
        for: "category",
      });
    },
  },

  data() {
    return {
      is_valid: true,
      search_product_input: "",
      ProductToAdd: {},

      ProductsHeaders: [
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "sku",
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: "code",
          align: "start",
          sortable: false,
          value: "code",
        },

        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "80px",
        },
      ],
    };
  },

  methods: {
    closeForm() {
      this.$store.dispatch("specialCategories/closeForm");
    },

    async saveForm(category) {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("specialCategories/addProducts", category);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.closeForm();
        } catch (error) {
          console.log(error);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    addProduct(products_item) {
      if (this.$refs.form.validate()) {
        const oProduct = this.specialCategoryPorducts.find(
          (item) => item.id === products_item.id
        );
        console.log(oProduct);
        if (!oProduct) {
          const product = this.listProducts.find(
            (item) => item.id === products_item.id
          );
          if (product) {
            products_item.code = product.code;
            products_item.name = product.name;
            products_item.sku = product.sku;

            const specialCategoryPorductsCopy = [
              ...this.specialCategoryPorducts,
            ];
            specialCategoryPorductsCopy.push(products_item);
            this.specialCategoryPorducts = specialCategoryPorductsCopy;
          }
        }
        this.ProductToAdd = {};
      }
    },

    addProductByEnterKey(event) {
      const Product = this.listProducts.find(
        (item) => item.name == event.target.value
      );
      this.addProduct(Product);
    },

    removeProduct(specialCategory) {
      this.specialCategoryPorducts.splice(
        this.specialCategoryPorducts
          .map((item) => item.id)
          .indexOf(specialCategory.id),
        1
      );
    },

    calcTotal(inbound_product) {
      const IProduct = this.specialCategoryPorducts.find(
        (item) => item.id === inbound_product.id
      );
      return (
        (Number(IProduct.purchase_price_ht) +
          (Number(IProduct.purchase_price_ht) * Number(IProduct.purchase_tva)) /
            100) *
        Number(IProduct.qte_in_inbound)
      );
    },
  },
};
</script>
